import React from "react";
import "./Login.css";
import loginPlaceholder from "../../assets/images/login/login-placeholder.png";
import {Button} from "antd";

const Login = ({loginHandle}) => {

	return (
		<div className="app-wrapper login">
			<div className='login__container'>
				<div className='login__column1'>
                    <div className='login__content'>
						Welcome to the Hill+Knowlton Podcast Network
					</div>
					
						<Button type={'primary'}
								onClick={loginHandle}
								className='login__action'>
								Sign In with SSO 
						</Button>
				</div>
				<div className='login__column2'>
					<img src={loginPlaceholder} alt='' className='login__placeholder'/>
				</div>
			</div>
		</div>
	);
};

export default Login;
