import React from 'react'
import ShowsCard from '../ShowsCard/index';
import './showsList.css'
import orkinProImg from '../../assets/images/OrkinProLogo.jpg';

const showsData = [
    {
        heading:"The Orkin Pro",
        category:"Business,Education,Leisure",
        episodeCount:1,
        routeName:"propodcast"
    }
]

const ShowsList = () => {
  return (
    <div className='shows-list__container'>
        {
            showsData.map((show,id)=>
                <ShowsCard 
                    id={id}
                    heading={show.heading} 
                    category={show.category}
                    episodeCount={show.episodeCount}
                    img={orkinProImg}
                    routeName={show.routeName}/>
            )
        }
    </div>
  )
}

export default ShowsList
